/*eslint-disable*/
import React, { useEffect } from "react";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Drawer, IconButton } from '@material-ui/core';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {ArrowDropDown, ArrowDropUp, Settings } from '@material-ui/icons';
import classNames from "classnames";
import Collapse from '@material-ui/core/Collapse';
import {getsocialurl,getTokenPrice} from "../../actions/v1/token"

const useStyles = makeStyles(styles);

export default function SidebarLinksAfterlogin(props) {
  const { classes1, handleDrawerOpen, open } = props

  const [open1, setOpen] = React.useState(true);
  const [open2, setOpen1] = React.useState(true);
  const [open3, setOpen2] = React.useState(true);
  const [sociallink, setsociallink] = React.useState();
  const [Tokenprice, setTokenprice] = React.useState(0);


  const handleClick = () => {
    setOpen(!open1);
  };
  const handleClick1 = () => {
    setOpen1(!open2);
  };

  const handleClick2 = () => {
    setOpen2(!open3);
  };

  useEffect(()=>{
    sidedetails()
  },[])

  const sidedetails =(async()=>{
    let {data} = await getsocialurl();
    console.log(data,"sociallinks")
    if(data&&data&&data!=undefined){
      setsociallink(data.data);
    }
    let {pirce} = await getTokenPrice();
    console.log(pirce,"pirce")
    if(pirce&&pirce.data&&pirce!=undefined){
      setTokenprice(pirce.data)
    }
  })

  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={classNames(classes1.drawer+ " dash_sidebar", {
        [classes1.drawerOpen]: open,
        [classes1.drawerClose + " minimumSidebar"]: !open
      })}
      classes={{
        paper: classNames({
          [classes1.drawerOpen]: open,
          [classes1.drawerClose]: !open
        })
      }}
      open={open}
    >
    <div className={classes1.toolbar} />
      <div className="sidebar_main">
        <List className="side_menu">
          <ListItem className="sideItem">
            <a href="https://www.spideybarter.com/" className="sideLink">
              <img src={require("../../assets/images/menu_icon_01.png")} alt="Icon"/> Home
            </a>
          </ListItem>
          <ListItem className="sideItem" onClick={handleClick}>
            <a href="javascript:void(0)" className="sideLink" role="button" aria-expanded="false" aria-controls="earningDropDown">
              <img src={require("../../assets/images/menu_icon_02.png")} alt="Icon"/>Trade
              {open1 ? <ArrowDropDown /> : <ArrowDropUp />}
            </a>
          </ListItem>
          <Collapse in={!open1} timeout="auto" unmountOnExit>
            <List className="sidebarDropdown">
              <ListItem>
                <a href="https://exchange.spideybarter.com/#/swap" className="sideLink">Exchange</a>
              </ListItem>
              <ListItem>
                <a href="https://exchange.spideybarter.com/#/pool" className="sideLink">Liquidity</a>
              </ListItem>
            </List>
          </Collapse>
        
          <ListItem className="sideItem">
            <a href="https://app.spideybarter.com/farms" className="sideLink">
              <img src={require("../../assets/images/menu_icon_03.png")} alt="Icon"/> Farms
            </a>
          </ListItem>
          <ListItem className="sideItem">
            <a href="https://app.spideybarter.com/pools" className="sideLink">
              <img src={require("../../assets/images/menu_icon_04.png")} alt="Icon"/>Pools
            </a>
          </ListItem>          

          <ListItem className="sideItem">
            <NavLink to="/nft" className="sideLink">
              <img src={require("../../assets/images/menu_icon_05.png")} alt="Icon"/>NFT
            </NavLink>
          </ListItem>

          <ListItem className="sideItem">
            <NavLink to="/lottery" className="sideLink">
              <img src={require("../../assets/images/menu_icon_06.png")} alt="Icon"/>Lottery
            </NavLink>
          </ListItem>      
        </List>

        

        <div className="sidebar_footer_wrapper">
          <div className="siderbar_settings">
            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classes.menuButton}>
              <Settings
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
          </div>
          <div className="sidebar_footer_top">
            <div className="token_stats">
              <img src={require("../../assets/images/logo_icon.png")} alt="Icon"/> {Tokenprice&&<span>${Tokenprice.price?Tokenprice.price.toFixed(7):"loading"}</span>}
            </div>
            {sociallink&&<ul className="sidebar_social_list">
              <li><a href={sociallink.twitch?`https://t.me/${sociallink.twitch}`:"#"} target="_blank"><i class="fab fa-telegram"></i></a></li>
              <li><a href={sociallink.twitter?`https://twitter.com/${sociallink.twitter}`:"#"} target="_blank"><i class="fab fa-twitter"></i></a></li>
            </ul>}
          </div>
        </div>
      </div>    
    </Drawer>
  );
}
       