import React, {
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';
import DETH_ABI from '../../ABI/DETH_ABI.json';
import EXCHANGE_ABI from '../../ABI/EXCHANGE.json';
import BEP721 from '../../ABI/BEP721.json';
import isEmpty from "../../lib/isEmpty";
import { Button, TextField } from '@material-ui/core';
import {
  getCurAddr,
  halfAddrShow
} from '../../actions/v1/User';

import {
  TokenCounts_Get_Detail_Action,
  BidApply_ApproveAction,
  acceptBId_Action,
  CancelBid_Action
} from '../../actions/v1/token';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;

export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {

  const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
  const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(false);
  const [MetaMaskAmt, setMetaMaskAmt] = React.useState(false);
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [AcceptCallStatus, setAccaptCallStatus] = React.useState('init');

  var {
    Set_WalletConnected,
    Set_UserAccountAddr,
    Set_UserAccountBal,
    Set_AddressUserDetails,
    Set_Accounts,
    Set_MyItemAccountAddr,
    Set_tokenCounts,
    Set_item,
    Set_tokenCounts_Detail,
    Set_MyTokenBalance,
    Set_Bids,
    Set_AccepBidSelect,
    Set_tokenBidAmt,
    Set_NoOfToken,
    Set_ValidateError,
    Set_TokenBalance,
    Set_YouWillPay,
    Set_YouWillPayFee,
    Set_YouWillGet,
    Set_BidApply_ApproveCallStatus,
    Set_BidApply_SignCallStatus,
    setTotaluserbidAmt,
    BidApply_ApproveCallStatus,
    totaluserbidAmt,
    WalletConnected,
    UserAccountAddr,
    UserAccountBal,
    AddressUserDetails,
    Accounts,
    MyItemAccountAddr,
    tokenCounts,
    item,
    tokenCounts_Detail,
    MyTokenBalance,
    Bids,
    AccepBidSelect,
    tokenBidAmt,
    NoOfToken,
    ValidateError,
    TokenBalance,
    YouWillPay,
    YouWillPayFee,
    YouWillGet,
    BidApply_ApproveCallStatus,
    BidApply_SignCallStatus,
    AllowedQuantity
  } = props;

  function PriceCalculate_this(data = {}) {
    var price = (typeof data.tokenBidAmt != 'undefined') ? data.tokenBidAmt : tokenBidAmt;
    var quantity = (typeof data.NoOfToken != 'undefined') ? data.NoOfToken : NoOfToken;
    if (price == '') { price = 0; }
    if (quantity == '') { quantity = 0; }
    if (isNaN(price) != true && isNaN(quantity) != true) {
      if (item.type == 721) {
        var totalPrice = price;
      }
      else {
        var totalPrice = price * quantity;
      }
      totalPrice = parseFloat(totalPrice);
      var per = (totalPrice * config.decimalvalues * config.fee) / 1e20;
      console.log("servide fee", per)
      var sendMMAmt = (totalPrice * config.decimalvalues) + per;
      console.log("servide fee", sendMMAmt, totalPrice * config.decimalvalues, per)
      setMetaMaskAmt(sendMMAmt)
      // var finalPrice = totalPrice + per;
      // var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      // Set_YouWillPay(totalPriceWithFee);

      var finalPrice = sendMMAmt / config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }
    else {
      Set_YouWillPay(0);
    }
  }


  const Validation_PlaceABid = (chk) => {
    if (chk) {
      var ValidateError = {};

      if (NoOfToken == '') {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      }
      else if (isNaN(NoOfToken) == true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      }
      else if (NoOfToken == 0) {
        ValidateError.NoOfToken = '"Quantity" is required';
      }
      else if (NoOfToken > AllowedQuantity) {
        ValidateError.NoOfToken = '"Quantity" must be less than or equal to ' + AllowedQuantity;
      }

      if (tokenBidAmt == '') {
        ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
      }
      else if (isNaN(tokenBidAmt) == true) {
        ValidateError.tokenBidAmt = '"Bid amount" must be a number';
      }
      else if (tokenBidAmt == 0) {
        ValidateError.tokenBidAmt = '"Bid amount" must be greater than 0';
      }
      // else if(tokenBidAmt > tokenCounts_Detail.TotalQuantity) {
      //   ValidateError.tokenBidAmt = '"Bid amount" must be less than or equal to '+tokenCounts_Detail.TotalQuantity;
      // }
      else if (item.minimumBid > tokenBidAmt) {
        ValidateError.tokenBidAmt = '"Bid amount" must be higher than or equal to ' + item.minimumBid;
      }

      // else if(YouWillPay > TokenBalance) {
      //   ValidateError.tokenBidAmt = 'Insufficient balance, Check your wallet balance';
      // }
      console.log('ValidateError', ValidateError);
      Set_ValidateError(ValidateError);
      return ValidateError;
    }
  }
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      var ValidateError = {};
      Set_ValidateError(ValidateError);
    } else {
      var ValidateError = {};
      ValidateError.NoOfToken = '"Quantity" must be a number';
      Set_NoOfToken("");
      Set_ValidateError(ValidateError);
    }
  }
  const inputChange = (e) => {
    console.log('inputChange');
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      switch (e.target.name) {
        case 'tokenBidAmt':
          if (value != '' && isNaN(value) == false && value > 0) {
            Set_tokenBidAmt(value);
            PriceCalculate_this({ tokenBidAmt: value });
            ValidateError.tokenBidAmt = '';
            Set_ValidateError(ValidateError);
          }
          else {
            ValidateError.tokenBidAmt = 'Enter Valid price';
            Set_ValidateError(ValidateError);
            Set_tokenBidAmt(value);
            PriceCalculate_this({ tokenBidAmt: value });
          }
          break;
        case 'NoOfToken':
          Set_NoOfToken(value);
          PriceCalculate_this({ NoOfToken: value });
          break;
        // code block
      }
      // window.$('#Validation_PlaceABid').click();
    }
  }

  async function FormSubmit_PlaceABid(e) {
    Set_BidformSubmit(true);
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var currAddr = window.web3.eth.defaultAccount;
      var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.Spideybarder);
      var tokenBal = await CoursetroContract.methods.balanceOf(currAddr).call();
      var balcheck = tokenBal / config.decimalvalues;
      if (balcheck == 0) {
        toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
        return false;
      }
      if (YouWillPay > balcheck) {
        toast.error("SpideyBarter Token Balance Insufficient", toasterOption);
        return false;
      }
      var errors = await Validation_PlaceABid(true);
      console.log("timed_validation:", errors)
      var errorsSize = Object.keys(errors).length;
      if (errorsSize != 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false;
      }
      window.$('#place_bid_modal').modal('hide');
      window.$('#edit_bid_modal').modal('hide');
      window.$('#proceed_bid_modal').modal('show');
    }
  }
  async function BidApply_ApproveCall() {
    console.log("bidding_amount11:", tokenBidAmt, MetaMaskAmt, YouWillPayFee, YouWillGet, Bids.myBid.tokenBidAmt, TokenBalance)

    if (!window.ethereum) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }

    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
    }


    Set_BidApply_ApproveCallStatus('processing');
    var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.Spideybarder);
    var currAddr = window.web3.eth.defaultAccount;
    var tokenBal = await CoursetroContract.methods.balanceOf(currAddr).call();
    var tokenBalance = tokenBal / config.decimalvalues;
    var getAllowance = await CoursetroContract.methods.allowance(currAddr, config.singleContract).call();
    console.log("allowance:", CoursetroContract, getAllowance, MetaMaskAmt)
    var sendVal = parseInt(MetaMaskAmt) + parseInt(getAllowance)+1000;
    if (tokenBalance == 0) {
      toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
      return false;
    }
    if (YouWillPay > tokenBalance) {
      toast.error("SpideyBarter Token Balance Insufficient", toasterOption);
      return false;
    }
    await CoursetroContract
      .methods
      .approve(
        config.singleContract,
        sendVal.toString()
      )
      .send({ from: Accounts })
      .then(async (result) => {
        toast.success("Approve Successfully", toasterOption);
        var BidData = {
          tokenCounts: item.tokenCounts,
          tokenBidAddress: UserAccountAddr,
          tokenBidAmt: tokenBidAmt.toString(),
          NoOfToken: item.type == 721 ? 1 : NoOfToken
        }
        var Resp = await BidApply_ApproveAction(BidData);

        if (Resp.data && Resp.data.type && Resp.data.type == 'success') {

          Set_BidApply_ApproveCallStatus('done');
        }
        else {
          toast.error("Approve failed", toasterOption);
          Set_BidApply_ApproveCallStatus('tryagain');
        }
      })
      .catch((error) => {
        toast.error("Approve failed", toasterOption);
        Set_BidApply_ApproveCallStatus('tryagain');
      })
  }
  // async function BidApply_ApproveCall() {
  //   console.log("bidding_amount11:",tokenBidAmt,MetaMaskAmt,YouWillPayFee,YouWillGet,Bids.myBid.tokenBidAmt,TokenBalance)

  //     if (!window.ethereum) {
  //       toast.warning("OOPS!..connect Your Wallet", toasterOption);
  //       return false;
  //     }

  //     var web3 = new Web3(window.ethereum);
  //     var currAddr = window.web3.eth.defaultAccount;
  //     if (!currAddr) {
  //       toast.warning("OOPS!..connect Your Wallet", toasterOption);
  //     }


  //     Set_BidApply_ApproveCallStatus('processing');
  //     var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddr[config.tokenSymbol]);
  //     console.log("allowance:",CoursetroContract)
  //     var currAddr = window.web3.eth.defaultAccount;
  //     var getAllowance = await CoursetroContract.methods.allowance(currAddr,exchangeAddress).call();
  //     var sendVal=parseInt(MetaMaskAmt)+parseInt(getAllowance);
  //      console.log("placebidAmt:",getAllowance,MetaMaskAmt,sendVal)
  //     CoursetroContract
  //     .methods
  //     .approve(
  //       exchangeAddress,
  //       sendVal.toString()
  //     )
  //     .send({from: Accounts})
  //     .then(async (result) => {
  //       toast.success("Approve Successfully", toasterOption);
  //       var BidData = {
  //         tokenCounts: item.tokenCounts,
  //         tokenBidAddress: UserAccountAddr,
  //         tokenBidAmt: tokenBidAmt.toString(),
  //         NoOfToken: item.type == 721 ? 1 : NoOfToken
  //       }
  //       var Resp = await BidApply_ApproveAction(BidData);

  //       if(Resp.data && Resp.data.type && Resp.data.type == 'success') {

  //         Set_BidApply_ApproveCallStatus('done');
  //       }
  //       else {
  //         toast.error("Approve failed", toasterOption);
  //         Set_BidApply_ApproveCallStatus('tryagain');
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Approve failed", toasterOption);
  //       Set_BidApply_ApproveCallStatus('tryagain');
  //     })
  // }
  async function BidApply_SignCall() {
    if (!window.ethereum) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return;
    }

    Set_BidApply_SignCallStatus('processing');

    web3.eth.personal.sign("Bidding a Art", currAddr, "Bid Placed")
      .then(async (result) => {
        toast.success("Bid sign successfully", toasterOption);
        Set_BidApply_SignCallStatus('done');
        setTimeout(() => window.$('#proceed_bid_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(() => {
        toast.error("Sign failed", toasterOption);
        Set_BidApply_SignCallStatus('tryagain');
      })
  }

  async function CancelBid_Proceed(curBid_val) {
    var payload = {
      tokenCounts: curBid_val.tokenCounts,
      tokenBidAddress: curBid_val.tokenBidAddress
    }
    var Resp = await CancelBid_Action(payload);
    if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.message) {
      if (Resp.data.toast.type == 'error') {
        toast.error(Resp.data.toast.message, toasterOption);
      }
      else if (Resp.data.toast.type == 'success') {
        toast.success(Resp.data.toast.message, toasterOption);
      }
    }
    setTimeout(() => window.$('.modal').modal('hide'), 600);
    window.location.reload();
  }
  async function AcceptBid_Proceed() {

    var curAddr = await getCurAddr();
    if (window.ethereum) {
  try{
        var web3 = new Web3(window.ethereum);
        var currAddr = window.web3.eth.defaultAccount;
        var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.Spideybarder);
        var tokenBal = await CoursetroContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call();
        var tokenBalance = tokenBal / config.decimalvalues;
      console.log(tokenBalance,"tokenbalance")
        var value = (parseFloat(YouWillPayFee)
          * parseFloat(AccepBidSelect.NoOfToken)) +
          (parseFloat(AccepBidSelect.tokenBidAmt)
            * parseFloat(AccepBidSelect.NoOfToken))

        if (tokenBalance >= value) {
          var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
          passAmt = parseInt(passAmt).toFixed(config.toFixed)
          passAmt = (passAmt * config.decimalvalues).toString();
          // alert(NoOfToken_NeedToSend);
          if (NoOfToken_NeedToSend) {
            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            console.log(CoursetroContract,"CoursetroContract")
            setAccaptCallStatus('processing');
            console.log("accept_bal:", YouWillPayFee, YouWillGet, passAmt)

            await CoursetroContract
              .methods
              .acceptBId(
                config.tokenSymbol,
                AccepBidSelect.tokenBidAddress,
                passAmt,
                item.tokenCounts,
                // NoOfToken_NeedToSend
              )
              .send({ from: Accounts })
              .then(async (result) => {

                console.log('result', result);
                var acceptBId_Payload = {
                  tokenCounts: item.tokenCounts,
                  NoOfToken: NoOfToken_NeedToSend, //AccepBidSelect.NoOfToken,
                  tokenBidAddress: AccepBidSelect.tokenBidAddress,
                  UserAccountAddr_byaccepter: curAddr.toLowerCase(),
                  transactionHash: result.transactionHash
                }
                var Resp = await acceptBId_Action(acceptBId_Payload);
                setAccaptCallStatus('done');
                setTimeout(() => window.$('.modal').modal('hide'), 600);
                window.location.reload();
              })
              .catch((err) => {
                console.log('err', err)
                toast.error("Accept failed", toasterOption);
                setAccaptCallStatus('tryagain');
              })



          }
        } else {
          toast.error("Bidder doesnt have Enough Balance ", toasterOption);
        }
      }catch(err){
        console.log(err,"error")
      }
      }
  }
    // async function AcceptBid_Proceed() {
    //     var curAddr = await getCurAddr();
    //     // var payload = {
    //     //   curAddr:curAddr,
    //     //   tokenCounts:tokenidval
    //     // };
    //     // TokenCounts_Get_Detail_Call(payload);
    //     if (window.ethereum) {
    //       var web3 = new Web3(window.ethereum);
    //       var CoursetroContract = new web3.eth.Contract(EXCHANGE_ABI, config.exchangeAddress);
    //       var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
    //       passAmt = passAmt.toFixed(config.toFixed)
    //       passAmt = (passAmt * config.decimalvalues).toString();
    //       if(NoOfToken_NeedToSend) {
    //         console.log('config.toFixed',config.toFixed)
    //        console.log("acceptBid+++:",config.tokenSymbol,AccepBidSelect.tokenBidAddress,passAmt,
    //          item.tokenCounts,item.contractAddress,item.type,NoOfToken_NeedToSend,
    //          YouWillGet,passAmt,YouWillPayFee)
    //         setAccaptCallStatus('processing');
    //         CoursetroContract
    //         .methods
    //         .acceptBId(
    //           config.tokenSymbol,
    //           AccepBidSelect.tokenBidAddress,
    //           passAmt,
    //           item.tokenCounts,
    //           item.contractAddress,
    //           item.type,
    //           NoOfToken_NeedToSend
    //         )
    //         .send({from:Accounts})
    //         .then(async (result) => {

    //           console.log('result', result);
    //           var acceptBId_Payload = {
    //             tokenCounts: item.tokenCounts,
    //             NoOfToken : NoOfToken_NeedToSend, //AccepBidSelect.NoOfToken,
    //             tokenBidAddress: AccepBidSelect.tokenBidAddress,
    //             UserAccountAddr_byaccepter: curAddr,
    //             transactionHash: result.transactionHash
    //           }
    //           var Resp = await acceptBId_Action(acceptBId_Payload);
    //           setAccaptCallStatus('done');
    //           setTimeout(() => window.$('.modal').modal('hide'), 600);
    //           window.location.reload();
    //         })
    //         .catch((err) => {
    //           console.log('err', err)
    //           setAccaptCallStatus('tryagain');
    //         })
    //       }
    //     }
    // }
    async function Placebidmodal() {
      setTimeout(() => window.$('#place_bid_modal').modal('hide'), 600);
      setTimeout(() => window.location.reload(false), 900);
    }
    useImperativeHandle(
      ref,
      () => ({
        async PlaceABid_Click() {
          Set_BidformSubmit(false);
          var connectwallet = localStorage.getItem("yedipsbardre");
          if (!connectwallet) {
            toast.error("Please connect to a Metamask wallet", toasterOption);
            return false;
          }
          //  console.log("bidding_amount:",Bids.myBid.tokenBidAmt,MyTokenBalance)/
          if (Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
            // Set_tokenBidAmt(0);
            Set_NoOfToken(Bids.myBid.NoOfToken);
          }
          window.$('#place_bid_modal').modal('show');
        },
        async PriceCalculate(data = {}) {
          PriceCalculate_this(data);
        },
        async AcceptBid_Select(curBid_val) {
          if (window.ethereum) {
            if (curBid_val && curBid_val.tokenBidAmt) {
              window.$('#accept_modal').modal('show');
              Set_AccepBidSelect(curBid_val);

              if (MyTokenBalance < curBid_val.pending) {
                Set_NoOfToken_NeedToSend(MyTokenBalance);
                var totalAmt = MyTokenBalance * curBid_val.tokenBidAmt;
              }
              else {
                Set_NoOfToken_NeedToSend(curBid_val.pending);
                var totalAmt = curBid_val.pending * curBid_val.tokenBidAmt;
              }
              var ServiceFee_val = (totalAmt * config.fee) / 1e20;
              var YouWillGet_Val = totalAmt - ServiceFee_val;
              Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
              Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
            }
          }
          else {
            // console.log("called")
            window.$('#connect_modal').modal('show')
          }
        },
        async CancelBid_Select(curBid_val) {
          if (
            curBid_val
            && curBid_val.pending > 0
            &&
            (
              curBid_val.status == 'pending'
              || curBid_val.status == 'partiallyCompleted'
            )
          ) {
            Set_AccepBidSelect(curBid_val);
            window.$('#cancel_modal').modal('show');
          }
          else {
            window.$('.modal').modal('hide')
          }
        }
      }),
    )

    useEffect(() => {
      Validation_PlaceABid(BidformSubmit);
      // alert(MyTokenBalance)
    }, [
      tokenBidAmt,
      NoOfToken
    ])

    return (
      <div>
        <div id="Validation_PlaceABid" onClick={() => Validation_PlaceABid(BidformSubmit)}></div>
        {/* place_bid Modal */}
        <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="place_bid_modalLabel">Place a bid</h5>
                <p className="text-center place_bit_desc">You are about to place a bid for</p>
                {/*  <p className="place_bit_desc_2">
                  <span className="text_red mr-2">Uniaqua</span>
                  by<span className="text_red ml-2">NFTbuyer</span>
                </p>*/}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(Placebidmodal)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px-0 pt-0">
                <form className="px-4 bid_form">
                  <label for="bid">Your bid</label>
                  <div class="input-group mb-3 input_grp_style_1">
                    <input
                      type="text"
                      name="tokenBidAmt"
                      id="tokenBidAmt"
                      class="form-control"
                      placeholder="Enter your bit amount"
                      aria-label="bid"
                      aria-describedby="basic-addon2"
                      onChange={inputChange}
                      autoComplete="off"
                    // value={tokenBidAmt}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">{config.tokenSymbol}</span>
                    </div>
                  </div>
                  {ValidateError.tokenBidAmt && <span className="text-danger"><br />{ValidateError.tokenBidAmt}</span>}
                  {item.type == config.multipleType && <label for="qty">Enter quantity <span className="label_muted pl-2">({AllowedQuantity} available)</span></label>}
                  {item.type == config.multipleType && <div class="mb-3 input_grp_style_1">
                    <input
                      type="text"
                      name="NoOfToken"
                      id="NoOfToken"
                      class="form-control"
                      placeholder="Enter your bit quantity"
                      onChange={inputChange}
                      autoComplete="off"
                      value={NoOfToken}
                      onKeyUp={onKeyUp}
                    />
                  </div>}
                  {ValidateError.NoOfToken && <span className="text-danger"><br />{ValidateError.NoOfToken}</span>}
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Your balance</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{UserAccountBal} {config.currencySymbol}</p>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Your bidding balance</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{TokenBalance} {config.tokenSymbol}</p>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Service fee</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{config.fee / config.decimalvalues}% <span>{config.tokenSymbol}</span></p>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">You will pay</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{YouWillPay}<span>{config.tokenSymbol}</span></p>
                    </div>
                  </div>

                  <div className="text-center">
                    {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                    <Button className="create_btn btn-block" onClick={() => FormSubmit_PlaceABid()} >Place a bid</Button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        {/* end place_bid modal */}
        {/* edit_bid Modal */}
        <div class="modal fade primary_modal" id="edit_bid_modal" tabindex="-1" role="dialog" aria-labelledby="edit_bid_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="edit_bid_modalLabel">Edit a bid</h5>
                <p className="text-center place_bit_desc">You are about to edit a bid for</p>
                <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span></p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px-0 pt-0">
                <form className="px-4 bid_form">
                  <label for="bid">Your bid</label>
                  <div class="input-group mb-3 input_grp_style_1">
                    <input
                      type="text"
                      name="tokenBidAmt"
                      id="tokenBidAmt"
                      class="form-control"
                      placeholder="Enter your bit amount"
                      aria-label="bid"
                      aria-describedby="basic-addon2"
                      onChange={inputChange}
                      value={tokenBidAmt}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">ETH</span>
                    </div>
                  </div>
                  {ValidateError.tokenBidAmt && <span className="text-danger"><br />{ValidateError.tokenBidAmt}</span>}
                  {item.type == config.multipleType && <label for="qty">Enter quantity <span className="label_muted pl-2">({AllowedQuantity} available)</span></label>}
                  {item.type == config.multipleType && <div class="mb-3 input_grp_style_1">
                    <input
                      type="text"
                      name="NoOfToken"
                      id="NoOfToken"
                      class="form-control"
                      placeholder="Enter your bit quantity"
                      onChange={inputChange}
                      value={NoOfToken}
                    />
                  </div>}
                  {ValidateError.NoOfToken && <span className="text-danger"><br />{ValidateError.NoOfToken}</span>}
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Your balance</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{UserAccountBal} {config.currencySymbol}</p>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Your bidding balance</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{TokenBalance} {config.tokenSymbol}</p>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Service fee</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{config.fee}% <span>{config.currencySymbol}</span></p>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">You will pay</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{YouWillPay}</p>
                    </div>
                  </div>

                  <div className="text-center">
                    {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                    <Button className="create_btn btn-block" onClick={() => FormSubmit_PlaceABid()} >Edit a bid</Button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        {/* end place_bid modal */}

        {/* proceed_bid Modal */}
        <div class="modal fade primary_modal" id="proceed_bid_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => window.location.reload(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div className="media approve_media">
                    <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                    {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                    <div className="media-body">
                      <p className="mt-0 approve_text">Approve</p>
                      <p className="mt-0 approve_desc">Checking balance and approving</p>
                    </div>
                  </div>
                  <div className="text-center my-3">
                    <Button
                      className={"btn-block " + ((BidApply_ApproveCallStatus == 'processing' || BidApply_ApproveCallStatus == 'done') ? 'btn_outline_red' : 'create_btn')}
                      disabled={(BidApply_ApproveCallStatus == 'processing' || BidApply_ApproveCallStatus == 'done')}
                      onClick={BidApply_ApproveCall}
                    >
                      {BidApply_ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                      {BidApply_ApproveCallStatus == 'init' && 'Approve'}
                      {BidApply_ApproveCallStatus == 'processing' && 'In-progress...'}
                      {BidApply_ApproveCallStatus == 'done' && 'Done'}
                      {BidApply_ApproveCallStatus == 'tryagain' && 'Try Again'}
                    </Button>
                  </div>
                  <div className="media approve_media">
                    <i className="fas fa-check mr-3" aria-hidden="true"></i>
                    <div className="media-body">
                      <p className="mt-0 approve_text">Signature</p>
                      <p className="mt-0 approve_desc">Create a signatute to place a bid</p>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <Button
                      className={"btn-block " + ((BidApply_ApproveCallStatus != 'done' || BidApply_SignCallStatus == 'processing' || BidApply_SignCallStatus == 'done') ? 'btn_outline_red' : 'create_btn')}
                      disabled={(BidApply_ApproveCallStatus != 'done' || BidApply_SignCallStatus == 'processing' || BidApply_SignCallStatus == 'done')}
                      onClick={BidApply_SignCall}
                    >
                      {BidApply_SignCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                      {BidApply_SignCallStatus == 'init' && 'Start'}
                      {BidApply_SignCallStatus == 'processing' && 'In-progress...'}
                      {BidApply_SignCallStatus == 'done' && 'Done'}
                      {BidApply_SignCallStatus == 'tryagain' && 'Try Again'}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* end proceed_bid modal */}

        {/* accept bid Modal */}
        <div class="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="accept_modalLabel">Accept bid</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px-0">
                <div className="img_accept text-center">
                  {
                    item && item.image && item.image.split('.').pop() == "mp4" ?
                      <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                      : <div>
                        {(item && item.image && item.image.split('.').pop() == 'mp3') ? (
                          <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid img_full_w" />
                        ) : (
                          <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                        )}
                      </div>
                  }
                </div>
                <p className="text-center accept_desc">
                  <span className="buy_desc_sm">You are about to accept bid for</span>
                  <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                  <span className="buy_desc_sm pl-2">from</span>
                  <span className="buy_desc_sm_bold pl-2">{halfAddrShow(AccepBidSelect.tokenBidAddress)}</span>
                </p>
                <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {config.tokenSymbol} for 1 edition(s)</p>
                <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Service fee in %</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{config.fee / config.decimalvalues}%<span>{config.tokenSymbol}</span></p>
                  </div>
                </div>
                <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Service fee in {config.tokenSymbol}</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{YouWillPayFee}<span>{config.tokenSymbol}</span></p>
                  </div>
                </div>
                <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">You will get</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{YouWillGet} {config.tokenSymbol}</p>
                  </div>
                </div>
                <form className="px-4">
                  <div className="text-center">
                    <Button
                      className="create_btn btn-block"
                      onClick={() => AcceptBid_Proceed()}
                      disabled={(AcceptCallStatus == 'processing')}
                    >
                      {AcceptCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                      Accept bid</Button>
                    <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* end accept bid modal */}

        {/* accept bid Modal */}
        <div class="modal fade primary_modal" id="cancel_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="accept_modalLabel">Cancel bid</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px-0">
                <div className="img_accept text-center">
                  {
                    item && item.image && item.image.split('.').pop() == "mp4" ?
                      <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                      :
                      item && item.image && item.image.split('.').pop() == "mp3" ?
                        <img src={`${config.Back_URL}/images/music.png`} alt="" className="img-fluid" style={{ backgroundColor: "white" }} />
                        :
                        <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                  }
                </div>
                <p className="text-center accept_desc">
                  <span className="buy_desc_sm">You are about to cancel bid for</span>
                  <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                </p>
                <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {config.tokenSymbol} for 1 edition(s)</p>
                <form className="px-4">
                  <div className="text-center">
                    <Button className="create_btn btn-block" onClick={() => CancelBid_Proceed(AccepBidSelect)}>Cancel bid</Button>
                    <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* end accept bid modal */}
      </div>
    )
  })

